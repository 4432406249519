import React, { useState, useEffect, useContext, useCallback } from 'react';
import { navigate, PageProps } from 'gatsby';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { SEO } from '@components';
import {
  LayoutMissionDesktop,
  ConfirmIdentity,
  LayoutMissionMobile,
} from '@components';
import { useAuth, useForm } from '@hooks';
import { AppContext } from '@context';
import { updateUser, completeMissionMutation, getUserMissions } from '@apollo';
import { useApolloClient } from '@apollo/client';
import { MissionsInfo } from '@data';
import { nextMissionModalInfo } from '@utils';
import { Route } from '@interfaces';

const M3ConfirmIdentity: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const isMobile = useMediaQuery('(min-width: 1240px)');
  const { openCongratsModal } = useContext(AppContext);
  const [form, , , handleChange] = useForm({});
  const { userMissions } = getUserMissions(); //!! to update mission
  const client = useApolloClient(); //!! to update mission
  const [confettiFire, setConfettiFire] = useState<boolean>(false);

  const categoryMissions = userMissions.filter(
    (obj) => obj.mission.category == MissionsInfo.ProfileCategory.name
  );
  const currentMission = categoryMissions.filter(
    (obj) => obj.mission.id == MissionsInfo.ProfileCategory.m3.id
  )[0];

  const handleClick = () => {
    // if (currentMission?.state == "PENDING") {
    try {
      updateUser(form, true, client);
      completeMissionMutation(client, currentMission.mission.id);
      setConfettiFire(true);
      openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    } catch (error) {
      console.log(error);
    }
    // } else {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // }
  };

  useEffect(() => {
    // if (currentMission?.state == "COMPLETED") {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // } else {
    //   setConfettiFire(false);
    // }
    setConfettiFire(false);
  }, [currentMission]);

  const onHouseClick = useCallback(() => {
    navigate(Route.completeProfile);
  }, []);
  return (
    <>
      <SEO />
      {isMobile ? (
        <LayoutMissionDesktop
          title={'Confirma tu identidad'}
          subtitle={
            'Sube 2 fotos con buena iluminación de tu Cédula de Identidad y responde el cuestionario'
          }
          confettiFire={confettiFire}
          onHouseClick={onHouseClick}
          number={4}
        >
          <ConfirmIdentity
            form={form}
            buttonText={'Teminar misión'}
            handleClick={handleClick}
          />
        </LayoutMissionDesktop>
      ) : (
        <LayoutMissionMobile
          title={'Confirma tu identidad'}
          subtitle={
            'Sube 2 fotos con buena iluminación de tu Cédula de Identidad y responde el cuestionario'
          }
          confettiFire={confettiFire}
          number={3}
          onHouseClick={() => navigate(Route.completeProfile)}
        >
          <ConfirmIdentity
            form={form}
            buttonText={'Teminar misión'}
            handleClick={handleClick}
          />
        </LayoutMissionMobile>
      )}
    </>
  );
};

export default M3ConfirmIdentity;
